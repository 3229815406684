import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  private isLoadingSubject = new BehaviorSubject<boolean>(false);
  isLoading$ = this.isLoadingSubject.asObservable();

  show(): void {
    setTimeout(() => {
      this.isLoadingSubject.next(true);
    });
  }

  hide(): void {
    setTimeout(() => {
      this.isLoadingSubject.next(false);
    });
  }
}
