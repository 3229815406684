import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { finalize, map } from 'rxjs/operators';
import { SpinnerService } from '../services/spinner.service';

@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {
  private countRequest = 0;

  constructor(
    private spinnerSvc: SpinnerService,
    private router: Router,
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    if (!this.countRequest) {
      this.spinnerSvc.show();
    }
    this.countRequest++;
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          if (event.body.message === 'Token esta vencido o no existe.') {
            localStorage.clear();
            this.router.navigateByUrl('/');
          }
        }
        return event;
      }),
      finalize(() => {
        this.countRequest--;
        if (!this.countRequest) {
          this.spinnerSvc.hide();
        }
      }),
    );
  }
}
