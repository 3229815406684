import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
} from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler) {
    const token = localStorage.getItem('token-core') || 'CORE';
    let authHeaderValue = token;

    if (
      request.url.includes('api/prestatario/cargar_cupo') ||
      request.url.includes('api/credito/generar_credito')
    ) {
      authHeaderValue = 'CORE';
    }

    const clonedRequest = request.clone({
      setHeaders: {
        'X-TRANSACTIONS-API-KEY-CORE': environment.apiKey,
        'X-TRANSACTIONS-API-TOKEN-CORE': environment.apiToken,
        'X-TRANSACTIONS-API-TOKEN-AUTH': authHeaderValue,
      },
    });

    // Continúa con la solicitud clonada
    return next.handle(clonedRequest);
  }
}
