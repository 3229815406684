import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'fundcore';
  isDarkMode: boolean = false;

  toggleTheme() {
    if (!this.isDarkMode) {
      document.body.classList.remove('theme');
      document.body.classList.add('theme-alternate');
    } else {
      document.body.classList.remove('theme-alternate');
      document.body.classList.add('theme');
    }

    this.isDarkMode = !this.isDarkMode;
  }
}
