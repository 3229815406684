import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

// Interceptors
import { SpinnerInterceptor } from './interceptors/spinner.interceptor';
import { AuthInterceptor } from './interceptors/auth-interceptor';

// Component
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { SpinnerComponent } from './components/spinner/spinner.component';

// Angular Material
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [SpinnerComponent, ConfirmDialogComponent],
  imports: [CommonModule, MatSnackBarModule, MatButtonModule, MatDialogModule],
  exports: [SpinnerComponent],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
})
export class CoreModule {}
